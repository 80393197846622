import React from "react";
import { GamePageData } from "./RecipeRescuePageData";

export const crystalCrusherPageData: GamePageData = {
    metaPageTitle: "Crystal Crusher - iDream Interactive",
    metaKeywords: "crystal crusher, facebook instant game",
    metaDescription: "Crush your way through levels by clearing crystals! Learn more about our second hyper casual mobile game.",
    pageTitle: "Crystal Crusher",
    descriptionTitle: "Crush your way through levels by clearing crystals!",
    description: <>
        <p>Crush your way through levels and clear all the crystals on the map to move forward. Do you have crystal fever? Uses simple 'tap to move’ gameplay.</p>
        <p>Crystal Crusher is available on the Facebook Instant game platform. This is our second hyper casual title with the intention that it’s easy to pick up and play. With one button control mechanic, it can be played with one hand, wherever you’d like.</p>
    </>,
    links: [
    ]
}
